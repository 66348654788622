import React, { useState } from 'react';
import 'react-international-phone/style.css';
import { PhoneInput } from 'react-international-phone';
import { MdWhatsapp } from 'react-icons/md';
import { useWhatsAppForm } from '../hooks/useWhatsAppForm';

const WhatsAppForm = (props: any) => {
  const { agent } = props;

  const {
    isOpenWhatsApp,
    handleClickWhatsAppButton,
    phoneNumber,
    isPhoneNumberValid,
    phoneNumberErrorMessage,
    handleChangePhoneNumber,
    handleClickSubmit,
  } = useWhatsAppForm({ agent });

  return (
    <>
      <button
        className="hidden desktop:block fixed bottom-8 right-8 bg-[#3fce3e] p-4 z-50 rounded-[32px] hover:bg-[#47c247]"
        onClick={handleClickWhatsAppButton}
      >
        <MdWhatsapp fontSize={32} color="white" />
      </button>
      <button
        className="desktop:hidden fixed bottom-4 right-4 bg-[#3fce3e] p-4 z-50 rounded-[32px] hover:bg-[#47c247]"
        onClick={handleClickWhatsAppButton}
      >
        <MdWhatsapp fontSize={24} color="white" />
      </button>
      <div
        className={`fixed desktop:bottom-28 desktop:right-8 bottom-24 flex right-4 w-[320px] border gap-4 bg-white justify-between flex-col border-[#3fce3e] p-6 z-50 rounded-[16px] transition-all duration-500 ${
          isOpenWhatsApp ? 'opacity-100' : 'translate-y-5 opacity-0'
        }`}
      >
        <div className="flex flex-col gap-2 w-full">
          <p className="text-[#000000E5]">Consult with WhatsApp</p>
          <p className="text-[#000000E5] text-[14px]">
            A WhatsApp message will be sent to the entered mobile phone number.
          </p>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-2">
            <PhoneInput
              defaultCountry="us"
              value={phoneNumber}
              inputStyle={{ width: '100%' }}
              onChange={handleChangePhoneNumber}
            />
            {!isPhoneNumberValid && (
              <div className="text-[red] font-thin">
                {phoneNumberErrorMessage}
              </div>
            )}
          </div>
          <button
            className="rounded-[4px] text-white bg-[#3fce3e] h-[48px]  w-full hover:bg-[#47c247]"
            onClick={handleClickSubmit}
          >
            Consult Now
          </button>
        </div>
      </div>
    </>
  );
};

export { WhatsAppForm };
