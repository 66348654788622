import React from 'react';

function GaTag(props: any) {
  const { id } = props;
  return (
    <button className="absolute w-full h-full left-0 top-0" id={id}></button>
  );
}

export { GaTag };
