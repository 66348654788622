import React from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';

function Landing4Desktop() {
  return (
    <div className={`w-full h-full flex gap-6 justify-center items-center`}>
      <div className="flex flex-col justify-center w-[656px] h-[656px] items-end gap-4">
        <p className="whitespace-pre-line text-[48px] font-semibold w-[539px]">
          {'Enjoy unparalleled\nflexibility'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099] w-[539px]">
          {
            "Tailor your designer's hours to your requirements\nand cancel anytime with absolute autonomy."
          }
        </p>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/desktop/calendar.png`}
        alt="designerhire calendar"
        width={656}
        height={656}
      />
    </div>
  );
}

function Landing4Mobile() {
  return (
    <div
      className={`w-full h-full flex justify-center items-center flex-col gap-8 `}
    >
      <div className="flex flex-col justify-center gap-4 text-center items-center">
        <p className="whitespace-pre-line text-[24px] font-semibold">
          {'Enjoy unparalleled\nflexibility'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099]">
          {
            "Tailor your designer's hours\nto your requirementsand cancel\nanytime with absolute autonomy."
          }
        </p>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/mobile/calendar.png`}
        alt="designerhire calendar"
      />
    </div>
  );
}

export function Landing4() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[620px] desktop:h-[800px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing4Desktop}
      MobileComponent={Landing4Mobile}
    />
  );
}
