import React, { useState } from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';

function Accordion({
  id,
  title,
  content,
  isLast,
  isOpen,
  onClick,
  browserHeight,
  textSize = 'text-base',
  mobileHeight,
}: {
  id?: string;
  title: string;
  content?: any;
  isLast?: boolean;
  isOpen?: boolean;
  onClick: () => void;
  browserHeight?: string;
  mobileHeight?: string;
  textSize?: string;
}) {
  return (
    <>
      <div
        className={`relative cursor-pointer overflow-hidden transition-all duration-500 border rounded-[16px] w-full px-8 ${
          !isLast && 'border-b border-grey-20'
        } ${isOpen ? browserHeight : 'h-16 '}`}
        onClick={onClick}
      >
        <button
          id={id}
          className="absolute left-0 top-0 h-full w-full"
        ></button>
        <div className="flex h-16 items-center justify-between">
          <div
            className={`text-[#000000E5] whitespace-pre-line ${textSize} font-bold`}
          >
            {title}
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/arrow_t.png`}
            alt="designerhire arrot_t"
            className={`w-3 text-grey-70 transition-all duration-500 ${
              isOpen && '-rotate-180'
            }`}
          />
        </div>
        <div className="border"></div>
        <div
          className={`whitespace-pre-line py-6 font-normal text-[#000000E5] ${textSize}`}
        >
          {content}
        </div>
      </div>
    </>
  );
}

function Landing2Desktop(props: any) {
  const { inView } = props;

  return (
    <div
      className={`w-full h-full flex flex-col gap-16 justify-center items-center`}
    >
      <div className="text-[64px] font-semibold text-center">
        <p>{'Unlike any other.'}</p>
        <div className="flex gap-4">
          <p>We</p>
          <div className={`text-[#795BF9] flex gqp-2 text-center`}>
            {'{'}
            <p
              className={`${
                inView ? 'opacity-100 w-[370px]' : 'opacity-0 w-[20px]'
              } transition-all duration-1000 whitespace-nowrap overflow-clip`}
            >
              fill the gaps
            </p>
            {'}'}
          </div>
          <p>as needed.</p>
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/desktop/table.png`}
        alt="designerhire table"
      />
    </div>
  );
}

function Landing2Mobile(props: any) {
  const { inView } = props;
  const [isOpen, setIsOpen] = useState([true, false, false]);

  return (
    <div
      className={`w-full h-full flex flex-col items-center justify-center gap-8`}
    >
      <div className="text-[24px] font-semibold text-center">
        <p>{'Unlike any other.'}</p>
        <div className="flex gap-1">
          <p>We</p>
          <div className={`text-[#795BF9] flex text-center`}>
            {'{'}
            <p
              className={`${
                inView ? 'opacity-100 w-[120px]' : 'opacity-0 w-[20px]'
              } transition-all duration-1000 whitespace-nowrap overflow-clip`}
            >
              fill the gaps
            </p>
            {'}'}
          </div>
          <p className="whitespace-nowrap">as needed.</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="w-full h-[224px] rounded-[16px] bg-[#E8E6FF99] flex py-6 px-8 flex-col gap-4">
          <p className="text-[#000000E5] font-bold">Designerhire</p>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p>👩‍💻</p>
                <p className="mt-1">Availability</p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                alt="designerhire CheckCircleFilled"
                style={{ height: '20px', width: '20px' }}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p>💡</p>
                <p className="mt-1">Unlimited requests </p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                alt="designerhire CheckCircleFilled"
                style={{ height: '20px', width: '20px' }}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p>💬</p>
                <p className="mt-1">Real-time feedback</p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                alt="designerhire CheckCircleFilled"
                style={{ height: '20px', width: '20px' }}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p>🚫</p>
                <p className="mt-1">Cancel anytime</p>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                alt="designerhire CheckCircleFilled"
                style={{ height: '20px', width: '20px' }}
              />
            </div>
          </div>
        </div>
        <Accordion
          title="Agencies"
          content={
            <div className="w-full h-[224px] flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>👩‍💻</p>
                    <p className="mt-1">Availability</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💡</p>
                    <p className="mt-1">Unlimited requests </p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💬</p>
                    <p className="mt-1">Real-time feedback</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>🚫</p>
                    <p className="mt-1">Cancel anytime</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
              </div>
            </div>
          }
          onClick={() => {
            if (isOpen[0]) setIsOpen([false, false, false]);
            else setIsOpen([true, false, false]);
          }}
          isOpen={isOpen[0]}
          browserHeight="h-[249px]"
        />
        <Accordion
          title="In-House"
          content={
            <div className="w-full h-[224px] flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>👩‍💻</p>
                    <p className="mt-1">Availability</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💡</p>
                    <p className="mt-1">Unlimited requests </p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                    alt="designerhire CheckCircleFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💬</p>
                    <p className="mt-1">Real-time feedback</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                    alt="designerhire CheckCircleFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>🚫</p>
                    <p className="mt-1">Cancel anytime</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
              </div>
            </div>
          }
          onClick={() => {
            if (isOpen[1]) setIsOpen([false, false, false]);
            else setIsOpen([false, true, false]);
          }}
          isOpen={isOpen[1]}
          browserHeight="h-[249px]"
        />
        <Accordion
          title="Freelancer"
          content={
            <div className="w-full h-[224px] flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>👩‍💻</p>
                    <p className="mt-1">Availability</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CheckCircleFilled.png`}
                    alt="designerhire CheckCircleFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💡</p>
                    <p className="mt-1">Unlimited requests </p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>💬</p>
                    <p className="mt-1">Real-time feedback</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <p>🚫</p>
                    <p className="mt-1">Cancel anytime</p>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/mobile/CloseFilled.png`}
                    alt="designerhire CloseFilled"
                    style={{ height: '20px', width: '20px' }}
                  />
                </div>
              </div>
            </div>
          }
          onClick={() => {
            if (isOpen[2]) setIsOpen([false, false, false]);
            else setIsOpen([false, false, true]);
          }}
          isOpen={isOpen[2]}
          browserHeight="h-[249px]"
        />
      </div>
    </div>
  );
}

export function Landing2() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[820px] desktop:h-[880px]',
        inViewAnimation: {
          desktop: false,
          mobile: false,
        },
      }}
      DesktopComponent={Landing2Desktop}
      MobileComponent={Landing2Mobile}
    />
  );
}
