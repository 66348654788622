import React from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';
import { Element } from 'react-scroll';
import { useConsultingForm } from '../hooks/useConsultingForm';

function Landing11Desktop() {
  const {
    companyName,
    isCompnayNameInvalid,
    handleChangeCompanyName,
    emailAddress,
    isEmailAddressInvalid,
    handleChangeEmailAddress,
    workRequirement,
    handleChangeWorkRequirement,
    handleClickFormSubmit,
  } = useConsultingForm({ agent: 'DESKTOP' });

  return (
    <div className={`w-full h-full flex gap-6 justify-between items-center`}>
      <div className="flex flex-col justify-between w-[470px] h-[600px]">
        <div className="flex flex-col justify-center items-start gap-6">
          <div className="">
            <p className="whitespace-pre-line text-[48px] font-semibold">
              {'Meet your'}
            </p>
            <p className="whitespace-pre-line text-[#795BF9] text-[48px] font-semibold">
              {'Dedicated Designer'}
            </p>
            <p className="whitespace-pre-line text-[48px] font-semibold">
              {'Instantly'}
            </p>
          </div>
          <div className="">
            <p className="whitespace-pre-line">
              {
                "Within 24 hours, we'll hook you up with your own dedicated designer. Test us out for 3 days, then see what you think."
              }
            </p>
            <p className="whitespace-pre-line font-bold">
              {'Expereince the difference for yourself.'}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <p className="text-[24px]"> 3-Day Free Trial Benefits : </p>
          <div className="flex flex-col gap-6">
            <div className="flex gap-4 items-center">
              <div className="w-[24px] h-fit flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/card.png`}
                />
              </div>
              <p className="font-light">Start without a credit card</p>
            </div>
            <div className="flex gap-4 items-center">
              <div className="w-[24px] h-fit flex justify-center items-center">
                {' '}
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/download.png`}
                />
              </div>

              <p className="font-light">Keep everything from your trial</p>
            </div>
            <div className="flex gap-4 items-center">
              <div className="w-[24px] h-fit flex justify-center items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/swap.png`}
                />
              </div>
              <p className="font-light">
                Unlimited Designer swaps until it feels just right
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-14 gap-8 justify-between px-10 w-[520px] h-[600px] bg-[#E8E6FF] rounded-[32px]">
        <div className="flex flex-col w-full justify-between max-h-[420px] flex-1">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Company Name</p>
              <p>*</p>
            </div>
            <input
              type="text"
              value={companyName}
              onChange={handleChangeCompanyName}
              className={`py-4 px-3 rounded-[4px] ${
                isCompnayNameInvalid && 'border border-[red]'
              }`}
              placeholder="Company Name"
            />
            {isCompnayNameInvalid && (
              <p className="text-[red] text-[14px] px-2">
                Please enter Company Name
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Email Address</p>
              <p>*</p>
            </div>
            <input
              type="text"
              value={emailAddress}
              onChange={handleChangeEmailAddress}
              className={`py-4 px-3 rounded-[4px] ${
                isEmailAddressInvalid && 'border border-[red]'
              }`}
              placeholder="example@example.com"
            />
            {isEmailAddressInvalid && (
              <p className="text-[red] text-[14px] px-2">
                Please enter Email Address
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Work Requirements</p>
            </div>
            <textarea
              value={workRequirement}
              onChange={handleChangeWorkRequirement}
              className="py-4 px-3 rounded-[4px] h-[116px] resize-none"
              placeholder="Work Requirements"
            />
          </div>
        </div>
        <button
          className="rounded-[4px] text-white bg-[#795BF9] h-[58px] hover:bg-[#6B4FE4]"
          onClick={handleClickFormSubmit}
        >
          Get Started Now
        </button>
      </div>
    </div>
  );
}

function Landing11Mobile() {
  const {
    companyName,
    isCompnayNameInvalid,
    handleChangeCompanyName,
    emailAddress,
    isEmailAddressInvalid,
    handleChangeEmailAddress,
    workRequirement,
    handleChangeWorkRequirement,
    handleClickFormSubmit,
  } = useConsultingForm({ agent: 'MOBILE' });

  return (
    <div className="flex flex-col w-full h-full justify-center gap-9">
      <div className="flex flex-col justify-center items-start gap-6">
        <div className="whitespace-pre-line text-[24px] font-semibold">
          <p>{'Meet your'}</p>
          <p>{'Dedicated Designer'}</p>
          <p>{'Instantly'}</p>
        </div>
      </div>
      <div className="flex flex-col gap-6 text-[#000000E5]">
        <div className="flex flex-col gap-6">
          <div className="flex gap-4 items-center">
            <div className="w-[24px] h-fit flex justify-center items-center">
              <img src={`${process.env.PUBLIC_URL}/images/desktop/card.png`} />
            </div>
            <p className="font-light">Start without a credit card</p>
          </div>
          <div className="flex gap-4 items-center">
            <div className="w-[24px] h-fit flex justify-center items-center">
              {' '}
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/download.png`}
              />
            </div>

            <p className="font-light">Keep everything from your trial</p>
          </div>
          <div className="flex gap-4 items-center">
            <div className="w-[24px] h-fit flex justify-center items-center">
              <img src={`${process.env.PUBLIC_URL}/images/desktop/swap.png`} />
            </div>
            <p className="font-light">Unlimited Designer swaps</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-8 gap-8 justify-between px-4 w-full bg-[#E8E6FF] rounded-[32px]">
        <div className="flex flex-col w-full gap-6 justify-between max-h-[420px] flex-1">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Company Name</p>
              <p>*</p>
            </div>
            <input
              type="text"
              value={companyName}
              onChange={handleChangeCompanyName}
              className={`py-4 px-3 rounded-[4px] ${
                isCompnayNameInvalid && 'border border-[red]'
              }`}
              placeholder="Company Name"
            />
            {isCompnayNameInvalid && (
              <p className="text-[red] text-[14px] px-2">
                Please enter Company Name
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Email Address</p>
              <p>*</p>
            </div>
            <input
              type="text"
              value={emailAddress}
              onChange={handleChangeEmailAddress}
              className={`py-4 px-3 rounded-[4px] ${
                isEmailAddressInvalid && 'border border-[red]'
              }`}
              placeholder="example@example.com"
            />
            {isEmailAddressInvalid && (
              <p className="text-[red] text-[14px] px-2">
                Please enter Email Address
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-1">
              <p className="text-[#000000E5]">Work Requirements</p>
            </div>
            <textarea
              value={workRequirement}
              onChange={handleChangeWorkRequirement}
              className="py-4 px-3 rounded-[4px] h-[116px] resize-none"
              placeholder="Work Requirements"
            />
          </div>
        </div>
        <button
          className="rounded-[4px] text-white bg-[#795BF9] h-[58px] hover:bg-[#6B4FE4]"
          onClick={handleClickFormSubmit}
        >
          Get Started Now
        </button>
      </div>
    </div>
  );
}

export function Landing11() {
  return (
    <Element name="consultingForm">
      <LandingPageLayout
        style={{
          outerLayout: 'h-[900px] desktop:h-[800px]',
          inViewAnimation: {
            desktop: true,
            mobile: true,
          },
        }}
        DesktopComponent={Landing11Desktop}
        MobileComponent={Landing11Mobile}
      />
    </Element>
  );
}
