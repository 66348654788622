import React from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';

function ChatBox(props: any) {
  const { inView, user, agent, text, time, delay } = props;

  const delayClass = `delay-[${delay}]`;

  if (user === 'Client') {
    return (
      <div
        className={`flex gap-4 items-end justify-end  transition-all duration-1000 ${delayClass} ${
          inView ? 'opacity-100' : 'translate-y-20 opacity-0 '
        } ${agent === 'Desktop' ? 'w-[480px]' : '[w-263px]'}`}
      >
        <p
          className={`text-[#00000099] ${
            agent === 'Desktop' ? 'text-[14px]' : 'text-[12px]'
          }`}
        >
          {time}
        </p>
        <p
          className={`shadow shadow-[#0000001F] whitespace-pre-line text-start py-4 px-6 rounded-[24px] rounded-br-none text-[#000000E5] bg-white ${
            agent === 'Desktop' ? 'text-[16px]' : 'text-[14px]'
          }`}
        >
          {text}
        </p>
      </div>
    );
  }

  if (user === 'Designer') {
    return (
      <div
        className={`w-[480px] flex gap-4 items-end justify-start transition-all duration-1000 ${delayClass} ${
          inView ? 'opacity-100' : 'translate-y-20 opacity-0'
        } ${
          agent === 'Desktop text-[14px]'
            ? 'w-[480px]'
            : '[w-263px] text-[12px]'
        }`}
      >
        {agent === 'Desktop' && (
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/Chat_Partner.png`}
          />
        )}
        <p
          className={`bg-[#A590FC] text-white shadow shadow-[#0000001F] whitespace-pre-line text-start py-4 px-6 rounded-[24px] rounded-bl-none ${
            agent === 'Desktop' ? 'text-[16px]' : 'text-[14px]'
          }`}
        >
          {text}
        </p>
        <p
          className={`text-[#00000099] ${
            agent === 'Desktop' ? 'text-[14px]' : 'text-[12px]'
          }`}
        >
          {time}
        </p>
      </div>
    );
  }

  return <></>;
}

function Landing3Desktop(props: any) {
  const { inView } = props;

  return (
    <div
      className={`w-full h-full flex gap-6 justify-center items-center transition-all duration-1000`}
    >
      <div className="max-w-[656px] h-[656px] flex flex-col gap-8 justify-center">
        <ChatBox
          inView={inView}
          user="Client"
          agent="Desktop"
          text={'Can you adjust the color scheme on\nour project?'}
          time="2:00 PM"
          delay="0.2s"
        />
        <ChatBox
          inView={inView}
          user="Designer"
          agent="Desktop"
          text={'Updated the colors,\nAwaiting your feedback. '}
          time="2:01 PM"
          delay="0.4s"
        />
        <ChatBox
          inView={inView}
          user="Client"
          agent="Desktop"
          text={'Can the blues be a bit darker?'}
          time="2:02 PM"
          delay="0.6s"
        />
        <ChatBox
          inView={inView}
          user="Designer"
          agent="Desktop"
          text={"Here's the update. \nDoes this look right?"}
          time="2:05 PM"
          delay="0.8s"
        />
        <ChatBox
          inView={inView}
          user="Client"
          agent="Desktop"
          text={'Looks good, appreciate the quick update!'}
          time="2:06 PM"
          delay="1s"
        />
      </div>
      <div className="flex flex-col justify-center w-[656px] h-[656px] items-end gap-4">
        <p className="whitespace-pre-line text-[48px] font-semibold w-[539px]">
          {'Sync problems are\na thing of the past.'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099] w-[539px]">
          {
            'Driven by real-time messaging, we ensures your design\ncollaboration as smooth and successful as possible.'
          }
        </p>
      </div>
    </div>
  );
}

function Landing3Mobile(props: any) {
  const { inView } = props;

  return (
    <div
      className={`w-full h-full flex justify-center items-center flex-col gap-8`}
    >
      <div className="flex flex-col justify-center items-center text-center gap-4">
        <p className="whitespace-pre-line text-[24px] font-semibold">
          {'Sync problems are\na thing of the past.'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099]">
          {
            'Driven by real-time messaging,\nwe ensures your designcollaboration\nas smooth and successful as possible.'
          }
        </p>
      </div>
      <div className="w-full h-[516px] flex flex-col gap-4 justify-center rounded-[24px] px-4 py-8 bg-[#E8E6FF99]">
        <ChatBox
          inView={inView}
          user="Client"
          agent="Mobile"
          text={'Can you adjust the color\nscheme onour project?'}
          time="2:00 PM"
          delay="0.2s"
        />
        <ChatBox
          inView={inView}
          user="Designer"
          agent="Mobile"
          text={'Updated the colors,\nAwaiting your feedback. '}
          time="2:01 PM"
          delay="0.4s"
        />
        <ChatBox
          inView={inView}
          user="Client"
          agent="Mobile"
          text={'Can the blues be\na bit darker?'}
          time="2:02 PM"
          delay="0.6s"
        />
        <ChatBox
          inView={inView}
          user="Designer"
          agent="Mobile"
          text={"Here's the update. \nDoes this look right?"}
          time="2:05 PM"
          delay="0.8s"
        />
        <ChatBox
          inView={inView}
          user="Client"
          agent="Mobile"
          text={'Looks good, appreciate\nthe quick update!'}
          time="2:06 PM"
          delay="1s"
        />
      </div>
    </div>
  );
}

export function Landing3() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[800px] desktop:h-[840px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing3Desktop}
      MobileComponent={Landing3Mobile}
    />
  );
}
