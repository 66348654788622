import React, { useEffect } from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';

function Landing5Desktop(props: any) {
  const { inView } = props;

  return (
    <div className={`w-full h-full flex gap-6 justify-center items-center `}>
      <div className="w-[654px] h-[656px] relative flex items-center justify-center">
        <div className="absolute w-[568px] h-[451px] left-[37px] top-[30px]">
          <div className="relative w-full h-full">
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Logo_desktop.png`}
              alt="designerhire designType_Logo"
              className={`bottom-[232px] left-[100px] absolute transition-all duration-1000 ease-in-out delay-[1s] ${
                inView ? 'translate-y-[120px] opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_graphic_desktop.png`}
              alt="designerhire designType_Graphic"
              className={`bottom-[252px] left-[255px] absolute transition-all duration-1000 ease-in-out delay-[0.9s] ${
                inView ? 'translate-y-[120px] opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Uiux_desktop.png`}
              alt="designerhire designType_Uiux"
              className={`bottom-[130px] left-2 absolute transition-all duration-1000 ease-in-out delay-[0.6s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Photoshop_desktop.png`}
              alt="designerhire designType_Photoshop"
              className={`bottom-[128px] left-[152px] absolute transition-all duration-1000 ease-in-out delay-[0.7s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Video_desktop.png`}
              alt="designerhire designType_Video"
              className={`bottom-[138px] right-10 absolute transition-all duration-1000 ease-in-out delay-[0.8s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Branding_desktop.png`}
              alt="designerhire designType_Branding"
              className={`bottom-0 right-0 absolute transition-all duration-1000 ease-in-out delay-[0.5s] ${
                inView ? 'translate-y-10 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/designType_Illustrator_desktop.png`}
              alt="designerhire designType_Illustrator"
              className={`bottom-0 left-2 absolute transition-all duration-1000 ease-in-out delay-[0.4s] ${
                inView ? 'translate-y-10 opacity-100' : ' opacity-0'
              }`}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center w-[656px] h-[656px] items-end gap-4">
        <p className="whitespace-pre-line text-[48px] font-semibold w-[539px]">
          {'Get easy access to\ndesigners matched to\nyour needs'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099] w-[539px]">
          {
            "Whether you require expertise in Figma, Photoshop,\nIllustrator, or 3D design, we've got you covered."
          }
        </p>
      </div>
    </div>
  );
}

function Landing5Mobile(props: any) {
  const { inView } = props;

  return (
    <div
      className={`w-full h-full flex justify-center items-center flex-col gap-8`}
    >
      <div className="flex flex-col justify-center items-center text-center gap-4">
        <p className="whitespace-pre-line text-[24px] font-semibold">
          {'Get easy access to\ndesigners matched to\nyour needs'}
        </p>
        <p className="whitespace-pre-line text-[20px] text-[#00000099]">
          {
            "Whether you require expertise\nin Figma, Photoshop, Illustrator,\nor 3D design, we've got you covered."
          }
        </p>
      </div>
      <div className="w-[320px] h-[220px] relative flex items-center justify-center">
        <div className="absolute w-[281px] h-[221px] left-[20px] -top-[40px]">
          <div className="relative w-full h-full">
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Logo_mobile.png`}
              alt="designerhire designType_Logo"
              className={`bottom-[122px] left-[35px] absolute transition-all duration-1000 ease-in-out delay-[1s] ${
                inView ? 'translate-y-[80px] opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Graphic_mobile.png`}
              alt="designerhire designType_Graphic"
              className={`bottom-[126px] left-[101px] absolute transition-all duration-1000 ease-in-out delay-[0.9s] ${
                inView ? 'translate-y-[80px] opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Uiux_mobile.png`}
              alt="designerhire designType_Uiux"
              className={`bottom-[84px] -left-[16px] absolute transition-all duration-1000 ease-in-out delay-[0.6s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Photoshop_mobile.png`}
              alt="designerhire designType_Photoshop"
              className={`bottom-[84px] left-[57px] absolute transition-all duration-1000 ease-in-out delay-[0.7s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Video_mobile.png`}
              alt="designerhire designType_Video"
              className={`bottom-[88px] right-10 absolute transition-all duration-1000 ease-in-out delay-[0.8s] ${
                inView ? 'translate-y-20 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Branding_mobile.png`}
              alt="designerhire designType_Branding"
              className={`bottom-0 right-0 absolute transition-all duration-1000 ease-in-out delay-[0.5s] ${
                inView ? 'translate-y-10 opacity-100' : ' opacity-0'
              }`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/mobile/designType_Illustrator_mobile.png`}
              alt="designerhire designType_Illustrator"
              className={`bottom-0 left-0 absolute transition-all duration-1000 ease-in-out delay-[0.4s] ${
                inView ? 'translate-y-10 opacity-100' : ' opacity-0'
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function Landing5() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[580px] desktop:h-[746px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing5Desktop}
      MobileComponent={Landing5Mobile}
    />
  );
}
