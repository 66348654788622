import React from 'react';
import { GaTag } from './GaTag';
import { Link } from 'react-scroll';

function FloatingBar(props: any) {
  return (
    <div className="hidden desktop:block w-full h-[148px] fixed bottom-0">
      <div className="w-full flex justify-center items-start h-full">
        <div className="max-w-[1336px] w-full flex justify-center items-center px-8">
          <div className="flex justify-between bg-[#E8E6FF] h-[104px] rounded-[24px] w-full py-6 px-8 items-center">
            <p className="text-[24px]">
              Get started in 30 seconds, Free for 3 days
            </p>
            <Link
              to="consultingForm"
              spy={true}
              smooth={true}
              className="relative w-[220px] h-[56px] bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] rounded-[32px] flex items-center justify-between p-2 pl-6 hover:cursor-pointer"
            >
              <GaTag id="floatingbnr_cv" />
              <p className="flex-1 text-white">3-Day Free Trial</p>
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr.png`}
                alt="designerhire arrow-tr"
                width={32}
                height={32}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export { FloatingBar };
