import { addDoc, collection } from 'firebase/firestore/lite';
import { useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { db } from '../firebase';

const useConsultingForm = (props: any) => {
  const { agent = 'DESKTOP' } = props;

  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      fontFamily: 'font-sans',
      fontSize: '16px',
      textAlign: agent === 'MOBILE' ? 'start' : 'center',
      zIndex: 1000,
    },
  });
  const [companyName, setCompanyName] = useState('');
  const [isCompnayNameInvalid, setIsCompanyNameInvalid] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailAddressInvalid, setIsEmailAddressInvalid] = useState(false);
  const [workRequirement, setWorkRequirement] = useState('');

  const handleChangeCompanyName = (e) => {
    if (e.target.value !== '') setIsCompanyNameInvalid(false);
    if (e.target.value === '') setIsCompanyNameInvalid(true);

    setCompanyName(e.target.value);
  };

  const handleChangeEmailAddress = (e) => {
    if (e.target.value !== '') setIsEmailAddressInvalid(false);
    if (e.target.value === '') setIsEmailAddressInvalid(true);

    setEmailAddress(e.target.value);
  };

  const handleChangeWorkRequirement = (e) => setWorkRequirement(e.target.value);

  const handleClickFormSubmit = async () => {
    if (companyName === '' || emailAddress === '') {
      if (companyName === '') setIsCompanyNameInvalid(true);
      if (emailAddress === '') setIsEmailAddressInvalid(true);
      return;
    }

    const consultingDocRef = collection(db, 'consulting');
    await addDoc(consultingDocRef, {
      conpanyName: companyName,
      emailAddress: emailAddress,
      workRequirement: workRequirement,
      createdAt: new Date().toISOString(),
    })
      .then(() => {
        openSnackbar('The form has been submitted successfully 🚀');
      })
      .catch((error) => {
        openSnackbar('An unknown error occurred 😭');
      });

    setCompanyName('');
    setEmailAddress('');
    setWorkRequirement('');
    setIsCompanyNameInvalid(false);
    setIsEmailAddressInvalid(false);
  };

  return {
    companyName,
    isCompnayNameInvalid,
    handleChangeCompanyName,
    emailAddress,
    isEmailAddressInvalid,
    handleChangeEmailAddress,
    workRequirement,
    handleChangeWorkRequirement,
    handleClickFormSubmit,
  };
};

export { useConsultingForm };
