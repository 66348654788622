import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { WhatsAppForm } from '../components/WhatsAppForm';

function LayoutOuter(props: any) {
  const {
    style,
    BackgroundComponent,
    DesktopComponent,
    MobileComponent,
    children,
  } = props;

  return (
    <div
      className={`relative w-full flex justify-center overflow-x-hidden overflow-y-auto font-sans ${
        !MobileComponent && 'hidden desktop:flex'
      }
        ${!DesktopComponent && 'desktop:hidden'}
      } ${style?.outerLayout ?? 'h-[800px] desktop:h-[880px]'}`}
    >
      {BackgroundComponent && <BackgroundComponent />}
      <WhatsAppForm />
      {children}
    </div>
  );
}

function LayoutInner(props: any) {
  const { style, DesktopComponent, MobileComponent } = props;

  const { ref, inView } = useInView({
    triggerOnce: false, // 반복적으로 애니메이션을 실행하려면 false
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`h-full relative ${
        style?.innerLayout ??
        'w-full max-w-[360px] tablet:w-full tablet:max-w-[528px] desktop:max-w-[1336px] desktop:w-full px-4 tablet:px-6 desktop:px-8'
      }`}
    >
      <div className={`w-full h-full overflow-y-clip`}>
        {/* Desktop */}
        <div
          className={`hidden desktop:block w-full h-full transition-all duration-1000 ${
            style.inViewAnimation.desktop &&
            (inView ? 'opacity-100' : 'translate-y-20 opacity-0')
          }`}
        >
          {DesktopComponent && <DesktopComponent inView={inView} />}
        </div>
        {/* Mobile */}
        <div
          className={`block desktop:hidden w-full h-full transition-all duration-1000 ${
            style.inViewAnimation.mobile &&
            (inView ? 'opacity-100' : 'translate-y-20 opacity-0')
          }`}
        >
          {MobileComponent && <MobileComponent inView={inView} />}
        </div>
      </div>
    </div>
  );
}

export function LandingPageLayout(props: any) {
  const { style, BackgroundComponent, DesktopComponent, MobileComponent } =
    props;

  return (
    <LayoutOuter
      style={style}
      BackgroundComponent={BackgroundComponent}
      DesktopComponent={DesktopComponent}
      MobileComponent={MobileComponent}
    >
      <LayoutInner
        style={style}
        DesktopComponent={DesktopComponent}
        MobileComponent={MobileComponent}
      />
    </LayoutOuter>
  );
}
