import React, { useState } from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/carousel.css';

const promotions = [
  {
    imgUrl: `${process.env.PUBLIC_URL}/images/desktop/promotion_1.png`,
    title: 'Branding',
    description: '20 designers await for you',
  },
  {
    imgUrl: `${process.env.PUBLIC_URL}/images/desktop/promotion_2.png`,
    title: 'Packaging',
    description: '14 designers await for you',
  },
  {
    imgUrl: `${process.env.PUBLIC_URL}/images/desktop/promotion_3.png`,
    title: '3D Design',
    description: '24 designers await for you',
  },
  {
    imgUrl: `${process.env.PUBLIC_URL}/images/desktop/promotion_2.png`,
    title: 'Infographic',
    description: '12 designers await for you',
  },
  {
    imgUrl: `${process.env.PUBLIC_URL}/images/desktop/promotion_1.png`,
    title: 'Web Design',
    description: '16 designers await for you',
  },
];

function Landing8Desktop() {
  return (
    <div className="w-full h-full flex flex-col gap-16 justify-center items-center">
      <div className="flex flex-col justify-center gap-4 desktop:max-w-[1336px] desktop:w-full desktop: px-8">
        <div className="flex flex-col justify-center w-full text-[48px] font-semibold">
          <div className="flex gap-4 ">
            <p className="whitespace-pre-line">Cover</p>
            <p className="text-[#795BF9]">any design</p>
          </div>
          <p>you may whish for</p>
        </div>
      </div>
      <div className="relative w-full flex justify-center">
        {/* <div className="absolute left-0 bg-[#FFFFFF] w-[200px] z-50 h-full opacity-80"></div>
        <div className="absolute right-0 bg-[#FFFFFF] w-[220px] z-50 h-full opacity-80"></div> */}
        <Carousel
          responsive={{
            desktop1: {
              breakpoint: { max: 1920, min: 1280 },
              items: 3,
            },
            desktop2: {
              breakpoint: { max: 2400, min: 1920 },
              items: 4,
            },
            desktop3: {
              breakpoint: { max: 10000, min: 2400 },
              items: 5,
            },
          }}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          deviceType="desktop1"
          removeArrowOnDeviceType={['desktop1', 'desktop2']}
          focusOnSelect={true}
          containerClass="carousel-container"
          itemClass="carousel-item"
          centerMode={true}
        >
          {promotions.map((promotion: any, index: number) => {
            const { imgUrl, title, description } = promotion;

            return (
              <div
                className={`relative hover:cursor-pointer group overflow-hidden rounded-[36px] flex justify-center`}
                key={index}
              >
                <img
                  src={imgUrl}
                  alt={imgUrl}
                  width={1000}
                  height={1000}
                  className="group-hover:scale-110 transition-all"
                />
                <p className="absolute bottom-20 left-6 text-white text-[34px]">
                  {title}
                </p>
                <div className="flex gap-2 absolute bottom-10 left-6 h-[30px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/desktop/human.png`}
                    alt="designerhire human"
                  />
                  <p className="text-white text-[20px] flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                    {description}
                  </p>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

function Landing8Mobile() {
  return (
    <div className="w-full h-full flex flex-col justify-center gap-8 px-4 tablet:px-6 tablet:items-center items-center">
      <div className="flex flex-col w-full max-w-[360px] tablet:max-w-[528px] tablet:px-6 px-4">
        <div className="flex flex-col justify-center w-full text-[24px] font-semibold">
          <div className="flex gap-2 ">
            <p className="whitespace-pre-line">Cover</p>
            <p className="text-[#795BF9]">any design</p>
          </div>
          <p>you may whish for</p>
        </div>
      </div>
      <div className="w-full h-[350px] flex overflow-x-auto gap-4">
        {promotions.map((promotion: any, index: number) => {
          const { imgUrl, title, description } = promotion;

          return (
            <div
              className={`relative hover:cursor-pointer h-[300px] min-w-[240px] group overflow-hidden rounded-[36px]`}
              key={index}
            >
              <img
                src={imgUrl}
                alt={imgUrl}
                height={300}
                className="group-hover:scale-110 transition-all rounded-[36px]"
              />
              <p className="absolute bottom-20 left-6 text-white text-[24px]">
                {title}
              </p>
              <div className="flex gap-2 absolute bottom-10 left-6">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/human.png`}
                  alt="designerhire human"
                />
                <p className="text-white text-[12px]">{description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Landing8() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[540px] desktop:h-[982px]',
        innerLayout: 'w-full',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing8Desktop}
      MobileComponent={Landing8Mobile}
    />
  );
}
