import React, { useState } from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';

function Accordion({
  id,
  title,
  content,
  isLast,
  isOpen,
  onClick,
  browserHeight,
  textSize = 'text-xl',
  mobileHeight,
}: {
  id?: string;
  title: string;
  content?: string;
  isLast?: boolean;
  isOpen?: boolean;
  onClick: () => void;
  browserHeight?: string;
  mobileHeight?: string;
  textSize?: string;
}) {
  return (
    <>
      <div
        className={`relative cursor-pointer overflow-hidden transition-all duration-500 ${
          !isLast && 'border-b border-grey-20'
        } ${isOpen ? browserHeight : 'h-24 '}`}
        onClick={onClick}
      >
        <button
          id={id}
          className="absolute left-0 top-0 h-full w-full"
        ></button>
        <div className="flex h-24 items-center justify-between py-5 ">
          <div
            className={`px-3 py-4 text-[#000000E5] whitespace-pre-line ${textSize}`}
          >
            {title}
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/ChevronLeftFilled.png`}
            alt="designerhire ChevronLeftFilled"
            className={`w-6 text-grey-70 transition-all duration-500 ${
              isOpen && '-rotate-180'
            }`}
          />
        </div>
        <div
          className={`whitespace-pre-line px-3 pr-10 font-normal text-[#000000E5] ${textSize}`}
        >
          {content}
        </div>
      </div>
    </>
  );
}

function Landing10Desktop() {
  const [isOpen, setIsOpen] = useState([false, false, false, false, false]);

  return (
    <div className="w-full h-full flex justify-center items-center flex-col gap-6">
      <p className="text-[48px] text-[#000000E5] font-semibold">FAQ</p>
      <div className="mt-10 flex w-[882px] flex-col rounded-[20px] px-5 shadow-normal">
        <Accordion
          id="qa_workExclusively"
          title="Does the designer work exclusively for our company during business hours?"
          content="Yes, during the assigned working hours, the designer focuses solely on your company's tasks."
          onClick={() => {
            if (isOpen[0]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([true, false, false, false, false]);
          }}
          isOpen={isOpen[0]}
          browserHeight="h-48"
        />
        <Accordion
          id="qa_realTime"
          title="Can I communicate with the designer in real-time?"
          content="Yes, the designer maintains real-time communication and collaborates during business hours. You can provide feedback and interact through chat."
          onClick={() => {
            if (isOpen[1]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, true, false, false, false]);
          }}
          isOpen={isOpen[1]}
          browserHeight="h-48"
        />
        <Accordion
          id="qa_sourceFile"
          title="Can I receive the original files?"
          content={`Yes, we provide the original files at no extra cost.`}
          onClick={() => {
            if (isOpen[2]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, true, false, false]);
          }}
          isOpen={isOpen[2]}
          browserHeight="h-40"
        />
        <Accordion
          id="qa_inviteMember"
          title="Can I invite team members to use the service together?"
          content="Yes, all plans allow for unlimited team members to use the service by using the chat room invitation link."
          onClick={() => {
            if (isOpen[3]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, false, true, false]);
          }}
          isOpen={isOpen[3]}
          browserHeight="h-48"
        />
        <Accordion
          id="qa_pricingPlan"
          title="Which pricing plan is suitable for me?"
          content='We recommend the 4 or 8-hour pricing plan if you require consistent communication with the designer. Otherwise, the 2-4 hour plan is suitable. For personalized recommendations, please contact us through the "Book a Demo" link for a consultation.'
          onClick={() => {
            if (isOpen[4]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, false, false, true]);
          }}
          isOpen={isOpen[4]}
          isLast
          browserHeight="h-60"
        />
      </div>
    </div>
  );
}

function Landing10Mobile() {
  const [isOpen, setIsOpen] = useState([false, false, false, false, false]);

  return (
    <div className="w-full h-full flex justify-center items-center flex-col gap-8">
      <p className="text-[24px] text-[#000000E5] font-semibold">FAQ</p>
      <div className="mt-10 flex w-full flex-col rounded-[20px] px-5 shadow-normal">
        <Accordion
          id="qa_workExclusively"
          title={
            'Does the designer work\nexclusively for our company\nduring business hours?'
          }
          content="Yes, during the assigned working hours, the designer focuses solely on your company's tasks."
          textSize="text-[16px]"
          onClick={() => {
            if (isOpen[0]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([true, false, false, false, false]);
          }}
          isOpen={isOpen[0]}
          browserHeight="h-48"
        />
        <Accordion
          id="qa_realTime"
          title="Can I communicate with the designer in real-time?"
          content="Yes, the designer maintains real-time communication and collaborates during business hours. You can provide feedback and interact through chat."
          textSize="text-[16px]"
          onClick={() => {
            if (isOpen[1]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, true, false, false, false]);
          }}
          isOpen={isOpen[1]}
          browserHeight="h-64"
        />
        <Accordion
          id="qa_sourceFile"
          title="Can I receive the original files?"
          content={`Yes, we provide the original files at no extra cost.`}
          textSize="text-[16px]"
          onClick={() => {
            if (isOpen[2]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, true, false, false]);
          }}
          isOpen={isOpen[2]}
          browserHeight="h-40"
        />
        <Accordion
          id="qa_inviteMember"
          title={'Can I invite team members to\nuse the service together?'}
          content="Yes, all plans allow for unlimited team members to use the service by using the chat room invitation link."
          textSize="text-[16px]"
          onClick={() => {
            if (isOpen[3]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, false, true, false]);
          }}
          isOpen={isOpen[3]}
          browserHeight="h-56"
        />
        <Accordion
          id="qa_pricingPlan"
          title="Which pricing plan is suitable for me?"
          content='We recommend the 4 or 8-hour pricing plan if you require consistent communication with the designer. Otherwise, the 2-4 hour plan is suitable. For personalized recommendations, please contact us through the "Book a Demo" link for a consultation.'
          textSize="text-[16px]"
          onClick={() => {
            if (isOpen[4]) setIsOpen([false, false, false, false, false]);
            else setIsOpen([false, false, false, false, true]);
          }}
          isOpen={isOpen[4]}
          isLast
          browserHeight="h-60"
        />
      </div>
    </div>
  );
}

export function Landing10() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[851px] desktop:h-[1053px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing10Desktop}
      MobileComponent={Landing10Mobile}
    />
  );
}
