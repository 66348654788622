import React from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';
import CountUp from 'react-countup';

function Landing6Desktop(props: any) {
  const { inView } = props;

  return (
    <div
      className={`w-full h-full flex justify-center items-center transition-all duration-1000 ${
        inView ? 'opacity-100' : 'translate-y-20 opacity-0'
      }`}
    >
      <div className="h-[360px] bg-[#0000000A] rounded-[40px] flex justify-between w-full p-20">
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={5}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'Access the elite top 5% of designers,\nselected through our rigorous precision\nsystem.'
            }
          </p>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={40}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'Save up to 40% on your design\nexpenditures without compromising\non quality.'
            }
          </p>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={93}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'We pride ourselves on a 93% customer\nsatisfaction rate, reflecting our\ncommitment to excellence.'
            }
          </p>
        </div>
      </div>
    </div>
  );
}

function Landing6Mobile(props: any) {
  const { inView } = props;

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full h-[752px] bg-[#0000000A] rounded-[32px] flex flex-col gap-8 p-8">
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={5}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'Access the elite top 5% of\ndesigners,selected through our\nrigorous precisionsystem.'
            }
          </p>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={40}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'Save up to 40% on your design\nexpenditures without\ncompromising on quality.'
            }
          </p>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex text-[#795BF9] h-[120px] font-semibold">
            <p className="text-[100px]">
              {' '}
              <CountUp
                start={0}
                end={93}
                duration={1.5}
                enableScrollSpy={true}
              />
            </p>
            <p className="text-[60px] mt-10">%</p>
          </div>
          <p className="whitespace-pre-line text-[#000000E5]">
            {
              'We pride ourselves on a 93%\ncustome nsatisfaction rate,\nreflecting our commitment to\nexcellence.'
            }
          </p>
        </div>
      </div>
    </div>
  );
}

export function Landing6() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'desktop:h-[524px] h-[856px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing6Desktop}
      MobileComponent={Landing6Mobile}
    />
  );
}
