import React from 'react';
import { useInView } from 'react-intersection-observer';
import { LandingPageLayout } from '../layouts/LandingPage';
import CountUp from 'react-countup';
import { Link } from 'react-scroll';
import { GaTag } from '../components/GaTag';

function Landing1Desktop(props: any) {
  const { inView } = props;
  return (
    <div className="relative h-[816px] w-full overflow-y-clip">
      <img
        className="absolute left-0 top-[30px] w-[180px] h-[36px]"
        src={`${process.env.PUBLIC_URL}/images/desktop/logo.png`}
      />
      <img
        className={`absolute left-[320px] -z-10`}
        src={`${process.env.PUBLIC_URL}/images/desktop/background.png`}
      />
      <div className="h-full gap-10 items-center justify-between flex g-4">
        <div className="flex flex-col gap-10">
          <div className="font-bold text-[76px] -tracking-[4px]">
            <div className="flex gap-2">
              <p className="text-[#000000E5]">Grab</p>
              <p className="text-[#795BF9] whitespace-nowrap">Pro Designers</p>
            </div>
            <p className="text-[#000000E5]">on a Monthly Basis</p>
          </div>
          <p className="whitespace-pre-line text-[20px] text-[#00000099]">
            {
              'Hire dedicated designers who can seamlessly integrate\nwith your existing team and value from day one.'
            }
          </p>
          <div className="relative flex gap-4">
            <Link
              to="consultingForm"
              spy={true}
              smooth={true}
              className="relative group w-[201px] h-[56px] hover:bg-gradient-to-r hover:pl-2 hover:from-white hover:to-white hover:border-2 hover:border-[#6B4FE4] bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] rounded-[32px] flex items-center hover:cursor-pointer p-2 pl-6"
            >
              <GaTag id="atf_cv" />
              <p className="flex-1 text-white group-hover:hidden block">
                3-Day Free Trial
              </p>
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr.png`}
                alt="designerhire arrow-tr"
                className="group-hover:hidden block"
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr_color.png`}
                alt="designerhire arrow-tr"
                className="group-hover:block hidden"
              />
              <p className="flex-1 text-[#6F42F0] group-hover:block hidden group-hover:text-center">
                Get Started
              </p>
            </Link>
            {/* <button className="w-[141px] h-[56px] border px-6 rounded-[28px] boder-[2px] border-[#A966FF] text-[#795BF9] font-medium bg-white">
              Start a Chat
            </button> */}
            <div className={`absolute top-[70px] -left-1 animate-bounce2`}>
              <div className="flex flex-col justify-center items-center">
                <div className="left-[90px] h-0 w-0 border-x-[8px] border-b-[10px] border-x-transparent border-b-[#795BF9E5]"></div>
                <div className="bg-[#795BF9E5] text-white w-[208px] flex justify-center py-1 px-2 rounded-[4px] shadow-2xl">
                  <p className="text-[14px] w-fit">
                    Limited offer only this month!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex w-[608px] h-[626px]">
          <img
            className="absolute right-0 top-0"
            src={`${process.env.PUBLIC_URL}/images/desktop/rectangle_1.png`}
          />
          <img
            className="absolute right-0 top-0 z-10"
            src={`${process.env.PUBLIC_URL}/images/desktop/phone.png`}
          />
          <img
            className="absolute left-0 bottom-0 "
            src={`${process.env.PUBLIC_URL}/images/desktop/rectangle_2.png`}
          />
          <div
            className={`absolute bg-white right-0 bottom-7 w-[225px] rounded-[32px] h-[120px] flex transition-all duration-1000 items-center p-6 ${
              inView && 'w-[442px]'
            }`}
          >
            <div
              className={`flex bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] text-transparent bg-clip-text font-semibold w-[200px] delay-500 transition-all duration-500 ${
                inView ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <p className="text-[60px]">
                <CountUp
                  start={0}
                  end={5236}
                  duration={1.5}
                  delay={0.5}
                  enableScrollSpy={true}
                />
              </p>
              <p className="text-[60px]">+</p>
            </div>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/avatars.png`}
            className="absolute right-0 bottom-7"
          />
          <p
            className={`w-[271px] h-[40px] absolute py-2 px-6 rounded-[16px] bg-[#FFFFFFCC] top-[140px] z-20 transition-all duration-1000 ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            {"Hi, I'm starting my work today."}
          </p>
          <p
            className={`w-[217px] h-[64px] absolute py-2 px-6 rounded-[16px] border border-1 border-white z-20 text-center top-[200px] left-[110px] whitespace-pre-line bg-[#E8E6FFE5] transition-all duration-1000 delay-300 ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            Cool, mind tweaking this design real quick?
          </p>
          <p
            className={`w-[259px] h-[40px] absolute py-2 px-6 rounded-[16px] bg-[#FFFFFFCC] top-[290px] z-20 left-[20px] transition-all duration-1000 delay-[0.6s] ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            Sure, will do it right away! 🚀
          </p>
        </div>
      </div>
    </div>
  );
}

function Landing1Mobile(props: any) {
  const { inView } = props;

  return (
    <div className="relative h-[695px] w-full flex justify-center items-center">
      <div className="h-[607px] gap-6 items-center flex-col justify-center flex">
        <div className="relative flex w-[297px] h-[301px]">
          <img
            className="absolute right-0 top-0 -z-30"
            src={`${process.env.PUBLIC_URL}/images/mobile/rectangle_2.png`}
          />
          <img
            className="absolute right-0 top-0 -z-20"
            src={`${process.env.PUBLIC_URL}/images/mobile/phone.png`}
          />
          <img
            className="absolute left-0 bottom-0 -z-30"
            src={`${process.env.PUBLIC_URL}/images/mobile/rectangle_1.png`}
          />
          <div
            className={`absolute bg-white right-0 bottom-2 w-[136px] rounded-[16px] h-[56px] flex transition-all duration-1000 items-center p-4 ${
              inView && 'w-[226px]'
            }`}
          >
            <div
              className={`flex bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] text-transparent bg-clip-text font-semibold w-[180px] delay-500 transition-all duration-500 ${
                inView ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <p className="text-[24px]">
                <CountUp
                  start={0}
                  end={5236}
                  duration={1.5}
                  delay={0.5}
                  enableScrollSpy={true}
                />
              </p>
              <p className="text-[24px]">+</p>
            </div>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/mobile/avatars.png`}
            className="absolute right-0 bottom-2"
          />
          <p
            className={`w-[122px] h-[48px] absolute py-1 text-[12px] text-center px-4 rounded-[8px] bg-[#FFFFFFCC] top-[5px] left-[5px] whitespace-pre-line transition-all duration-1000 ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            {"Hi, I'm starting\nmy work today."}
          </p>
          <p
            className={`w-[165px] h-[48px] absolute py-1 text-[12px] px-4 rounded-[8px] border border-1 border-white text-center top-[65px] left-[30px] whitespace-pre-line bg-[#E8E6FFE5] transition-all duration-1000 delay-300 ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            {'Cool, mind tweaking\nthis design real quick?'}
          </p>
          <p
            className={`w-[118px] h-[48px] absolute py-1 text-[12px] text-center px-4 rounded-[8px] bg-[#FFFFFFCC] top-[130px] left-[5px] delay-[0.6s] transition-all duration-1000 ${
              inView ? 'opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            {'Sure, will do it\nright away! 🚀'}
          </p>
        </div>
        <div className="flex flex-col w-[328px] h-[282px] items-center">
          <div className="font-bold text-[34px] -tracking-[1px]">
            <div className="flex gap-2">
              <p className="text-[#000000E5]">Grab</p>
              <p className="text-[#795BF9]">Pro Designers</p>
            </div>
            <p className="text-[#000000E5]">on a Monthly Basis</p>
          </div>
          <p className="whitespace-pre-line text-[#00000099] text-center">
            {
              'Hire dedicated designers who can\nseamlessly integrate with your existing\nteam and value from day one.'
            }
          </p>
          <div className="flex flex-col items-center gap-4 mt-6">
            <Link
              to="consultingForm"
              spy={true}
              smooth={true}
              className="relative group w-[185px] hover:w-[185px] h-[48px] hover:bg-gradient-to-r hover:pl-2 hover:from-white hover:to-white hover:border-2 hover:border-[#6B4FE4] bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] rounded-[32px] flex items-center justify-between p-2 pl-4"
            >
              <GaTag id="atf_cv" />
              <p className="flex-1 text-white group-hover:hidden block">
                3-Day Free Trial
              </p>
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr.png`}
                alt="designerhire arrow-tr"
                width={32}
                height={32}
                className="group-hover:hidden block"
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr_color.png`}
                alt="designerhire arrow-tr"
                width={32}
                height={32}
                className="group-hover:block hidden"
              />
              <p className="flex-1 text-[#6F42F0] group-hover:block hidden group-hover:text-center">
                Get Started
              </p>
            </Link>
            {/* <button className="w-fit text-[#795BF9] font-medium ">
              Start a Chat
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Landgin1Background() {
  return (
    <>
      <div className="absolute bg-gradient-to-tr from-[#F4F3FF4D] to-[#E1DFFF4D] w-full h-full -z-50"></div>
      <div className="absolute bg-white w-full h-[24px] rounded-t-[24px] desktop:h-[64px] -z-40 desktop:rounded-t-[64px] bottom-0"></div>
    </>
  );
}

export function Landing1() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[750px] desktop:h-[880px]',
        inViewAnimation: {
          desktop: false,
          mobile: false,
        },
      }}
      BackgroundComponent={Landgin1Background}
      DesktopComponent={Landing1Desktop}
      MobileComponent={Landing1Mobile}
    />
  );
}
