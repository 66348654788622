import React from 'react';
import SnackbarProvider from 'react-simple-snackbar';
import { Landing1 } from './pages/Landing1';
import { Landing2 } from './pages/Landing2';
import { Landing3 } from './pages/Landing3';
import { Landing4 } from './pages/Landing4';
import { Landing5 } from './pages/Landing5';
import { Landing6 } from './pages/Landing6';
import { Landing8 } from './pages/Landing8';
import { Landing7 } from './pages/Landing7';
import { Landing9 } from './pages/Landing9';
import { Landing10 } from './pages/Landing10';
import { FloatingBar } from './components/FloatingBar';
import { Landing11 } from './pages/Landing11';

function App() {
  return (
    <SnackbarProvider>
      <div className="-webkit-font-sans">
        <Landing1 />
        <Landing2 />
        <Landing3 />
        <Landing4 />
        <Landing5 />
        <Landing6 />
        <Landing7 />
        <Landing8 />
        <Landing11 />
        <Landing9 />
        <Landing10 />
        <FloatingBar />
      </div>
    </SnackbarProvider>
  );
}

export default App;
