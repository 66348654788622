// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyB-_gkxUDde-BxqoaT-onyeppedZ-lSMvs',
  authDomain: 'global-designer-hire-d08ca.firebaseapp.com',
  projectId: 'global-designer-hire-d08ca',
  storageBucket: 'global-designer-hire-d08ca.appspot.com',
  messagingSenderId: '91585387544',
  appId: '1:91585387544:web:02cb63f5fa47733bc7f76d',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
