import React from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';
import CountUp from 'react-countup';
import { Link } from 'react-scroll';
import { GaTag } from '../components/GaTag';

function Landing9Desktop() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex gap-4 w-full">
        <div className="flex-1 max-w-[940px] h-[442px] bg-gradient from-[#A966FF33] bg-[#6B4FE433] rounded-[56px] py-16 px-12 flex flex-col justify-between">
          <div className="flex flex-col text-[48px] font-semibold">
            <p>Stop hassling,</p>
            <div className="flex gap-2">
              <p>Your</p>
              <p className="text-[#795BF9]">perfect designer</p>
              <p>awaits.</p>
            </div>
            <p></p>
          </div>
          <p className="whitespace-pre-line text-[20px]">
            {
              'Designerhire gets rid of all the headaches of working\nwith independent freelancer.'
            }
          </p>
          <div className="flex justify-between items-center">
            <Link
              to="consultingForm"
              spy={true}
              smooth={true}
              className="relative self-end group w-[292px] h-[56px] hover:bg-gradient-to-r hover:pl-2 hover:from-white hover:to-white hover:border-2 hover:border-[#6B4FE4] bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] rounded-[32px] flex items-center hover:cursor-pointer p-2 pl-6"
            >
              <GaTag id="bottom_cv" />
              <p className="flex-1 text-white text-center group-hover:hidden block">
                Meet Your Designer NOW
              </p>
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr.png`}
                alt="designerhire arrow-tr"
                className="group-hover:hidden block"
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr_color.png`}
                alt="designerhire arrow-tr"
                className="group-hover:block hidden"
              />
              <p className="flex-1 text-[#6F42F0] group-hover:block hidden group-hover:text-center">
                3-day Free Trial
              </p>
            </Link>
            <img
              src={`${process.env.PUBLIC_URL}/images/desktop/avatars2.png`}
              alt="designerhire avatars2"
            />
          </div>
        </div>
        <div className="w-[360px] h-[442px] from-[#A966FF] to-[#6B4FE4] rounded-[56px] bg-gradient-to-r flex py-6 pl-10">
          <div className="self-end flex flex-col text-white">
            <p>designers</p>
            <div className=" text-[80px] flex gap-1 leading-[80px]">
              <CountUp
                start={0}
                end={5236}
                duration={1.5}
                enableScrollSpy={true}
              />
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Landing9Mobile() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full h-[430px] bg-gradient from-[#A966FF33] bg-[#6B4FE433] rounded-[16px] py-8 pb-3 px-3 flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col text-[24px] font-semibold">
            <p>Stop hassling,</p>
            <div className="flex gap-2">
              <p>Your</p>
              <p className="text-[#795BF9]">perfect designer</p>
            </div>
            <p>awaits.</p>
          </div>
          <p className="whitespace-pre-line text-[#00000099]">
            {
              'Designerhire gets rid of\nall the headaches of working with\nindependent freelancer.'
            }
          </p>
        </div>
        <div className="h-[72px] w-full flex justify-end">
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/avatars2.png`}
            alt="designerhire avatars2"
            height="72px"
          />
        </div>
        <Link
          to="consultingForm"
          spy={true}
          smooth={true}
          className="relative group w-[260px] h-[56px] hover:bg-gradient-to-r hover:pl-2 hover:from-white hover:to-white hover:border-2 hover:border-[#6B4FE4] bg-gradient-to-r from-[#A966FF] to-[#6B4FE4] rounded-[32px] flex items-center hover:cursor-pointer p-2 pl-6"
        >
          <GaTag id="bottom_cv" />
          <p className="flex-1 text-white text-center group-hover:hidden block">
            Meet Your Designer NOW
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr.png`}
            alt="designerhire arrow-tr"
            className="group-hover:hidden block"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/desktop/arrow_tr_color.png`}
            alt="designerhire arrow-tr"
            className="group-hover:block hidden"
          />
          <p className="flex-1 text-[#6F42F0] group-hover:block hidden group-hover:text-center">
            3-day Free Trial
          </p>
        </Link>
      </div>
    </div>
  );
}

export function Landing9() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[546px] desktop:h-[682px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing9Desktop}
      MobileComponent={Landing9Mobile}
    />
  );
}
