import React, { useState } from 'react';
import { LandingPageLayout } from '../layouts/LandingPage';
import { GaTag } from '../components/GaTag';
import { Link } from 'react-scroll';

const priceTypes = [
  {
    id: 'pricing_starter',
    title: 'Starter',
    description: 'Work 2 hours per week day\n40 hours per month',
    price: [
      {
        value: 1500,
        currency: '$',
      },
    ],
  },
  {
    id: 'pricing_basic',
    title: 'Basic',
    description: 'Work 3 hours per week day\n60 hours per month',
    price: [
      {
        value: 2100,
        currency: '$',
      },
    ],
  },
  {
    id: 'pricing_pro',
    title: 'Pro',
    description: 'Work 4 hours per week day\n80 hours per month',
    price: [
      {
        value: 2800,
        currency: '$',
      },
    ],
  },
  {
    id: 'pricing_enterprise',
    title: 'Enterprise',
    description: 'Work 8 hours per week day\n160 hours per month',
    price: [
      {
        value: 5500,
        currency: '$',
      },
    ],
  },
];

function Landing7Desktop() {
  const [priceTypeSelected, setPriceTypeSelected] = useState<string>('');

  return (
    <div className="w-full h-full flex gap-6 justify-center items-center">
      <div className="flex flex-col justify-center w-full h-full gap-16">
        <div className="flex flex-col justify-center w-full">
          <p className="whitespace-pre-line text-[48px] font-semibold w-[539px]">
            {'Pricing'}
          </p>
          <p className="whitespace-nowrap text-[20px] text-[#00000099] w-[539px]">
            {
              'Find the subscription that makes the most sense for you and your team.'
            }
          </p>
        </div>
        <div className="flex gap-6">
          <div className=" h-[840px] w-[543px] flex flex-col rounded-[24px] bg-[#0000000A] py-12 px-10 gap-10">
            <p className="text-[34px] font-bold">Core Features</p>
            <div className="border"></div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>1 : 1 Designer Match</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Swap Designer Anytime</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Live Chat for real-time updates</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Sub or unsub freely</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Get source files</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Flex scheduling and pausing</p>
              </div>
            </div>
          </div>
          <div className=" h-[840px] flex-1 max-w-[769px] flex flex-col gap-6">
            {priceTypes.map((priceType: any, index: number) => {
              const { id, title, description, price } = priceType;
              return (
                <div
                  key={index}
                  className={`relative rounded-[24px] border-[3px] flex justify-between p-8 pl-6 h-[166px] hover:cursor-pointer ${
                    priceTypeSelected === title && 'border-[#795BF9]'
                  }`}
                  onClick={() => setPriceTypeSelected(title)}
                >
                  <GaTag id={id} />
                  <div className="flex gap-4">
                    <div className="self-start">
                      <input
                        type="radio"
                        value={title}
                        checked={priceTypeSelected === title}
                        className="mt-3 accent-[#795BF9] w-4 h-4"
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[34px] font-bold">{title}</p>
                      <p className="text-[#000000E5] whitespace-pre-line">
                        {description}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <p className="text-[48px] font-semibold">
                      {price[0].currency}
                      {price[0].value}
                    </p>
                    <p className="mt-7">/month</p>
                  </div>
                </div>
              );
            })}
            <Link
              to="consultingForm"
              spy={true}
              smooth={true}
              className={`relative text-center rounded-[16px] bg-[#795BF9] text-white h-[80px] p-6 text-[20px] font-bold shadow-2xl ${
                priceTypeSelected !== '' && 'animate-bounce2'
              }`}
            >
              <GaTag id="pricing_cv" />
              Book a Demo | 3-day Free Trial
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Landing7Mobile() {
  const [priceTypeSelected, setPriceTypeSelected] = useState<string>('Starter');

  return (
    <div className="w-full h-full flex gap-6 justify-center items-center">
      <div className="flex flex-col justify-center w-full h-full gap-8">
        <div className="flex flex-col justify-center w-full">
          <p className="whitespace-pre-line text-[24px] font-semibold">
            {'Pricing'}
          </p>
          <p className="whitespace-pre-line text-[#00000099]">
            {
              'Find the subscription that makes\nthe most sense for you and your team.'
            }
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className=" flex flex-col rounded-[24px] bg-[#0000000A] py-6 px-4 gap-4">
            <p className="text-[20px] font-bold">Core Features</p>
            <div className="border"></div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>1 : 1 Designer Match</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Swap Designer Anytime</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Live Chat for real-time updates</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Sub or unsub freely</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Get source files</p>
              </div>
              <div className="flex gap-4 items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/images/desktop/checked.png`}
                  alt="designerhire checked"
                />
                <p>Flex scheduling and pausing</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            {priceTypes.map((priceType: any, index: number) => {
              const { id, title, description, price } = priceType;
              return (
                <div
                  key={index}
                  className={`relative rounded-[24px] border-[2px] flex justify-between p-8 pl-6 ${
                    priceTypeSelected === title && 'border-[#795BF9]'
                  }`}
                  onClick={() => setPriceTypeSelected(title)}
                >
                  <GaTag id={id} />
                  <div className="flex gap-4 flex-col w-full">
                    <div className="flex justify-between">
                      <p className="text-[20px] font-bold">{title}</p>
                      <div className="flex gap-2">
                        <p className="text-[24px] font-semibold">
                          {price[0].currency}
                          {price[0].value}
                        </p>
                        <p className="mt-2">/month</p>
                      </div>
                    </div>
                    <p className="text-[#000000E5] text-[14px] whitespace-pre-line">
                      {description}
                    </p>
                    {priceTypeSelected === title && (
                      <Link
                        to="consultingForm"
                        spy={true}
                        smooth={true}
                        className="rounded-[8px] bg-[#795BF9] text-white w-full h-[40px] py-2 px-4 text-[14px] font-semibold animate-bounce2"
                      >
                        <GaTag id="pricing_cv" />
                        Book a Demo | 3-day Free Trial
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function Landing7() {
  return (
    <LandingPageLayout
      style={{
        outerLayout: 'h-[1303px] desktop:h-[1251px]',
        inViewAnimation: {
          desktop: true,
          mobile: true,
        },
      }}
      DesktopComponent={Landing7Desktop}
      MobileComponent={Landing7Mobile}
    />
  );
}
