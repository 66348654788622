import React, { useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import 'react-international-phone/style.css';
import { PhoneInput } from 'react-international-phone';
import { MdWhatsapp } from 'react-icons/md';
import axios from 'axios';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const getPhoneNumberNational = (phoneNumber: string) => {
  const phoneNumberParsed = phoneUtil.parseAndKeepRawInput(phoneNumber);
  const contryCode = phoneNumberParsed.getCountryCode();
  const nationalNumber = phoneNumberParsed.getNationalNumber();
  const phoneNumberNational = `${contryCode}${nationalNumber}`;

  return phoneNumberNational;
};

const checkPhoneNumberValid = (phoneNumber: string) => {
  if (phoneNumber === '') {
    return { isValid: false, errorMessage: 'Please enter the Phone Number' };
  }

  try {
    return {
      isValid: phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phoneNumber),
      ),
      errorMessage: 'Phone Number is not valid',
    };
  } catch (error) {
    return {
      isValid: false,
      errorMessage: 'An unknown error occurred 😭',
    };
  }
};

const useWhatsAppForm = (props: any) => {
  const { agent = 'DESKTOP' } = props;

  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      fontFamily: 'font-sans',
      fontSize: '16px',
      textAlign: agent === 'MOBILE' ? 'start' : 'center',
      zIndex: 1000,
    },
  });

  const [isOpenWhatsApp, setIsOpenWhatsApp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

  const handleClickWhatsAppButton = () => {
    setPhoneNumber('');
    setIsPhoneNumberValid(true);
    setIsOpenWhatsApp((prev) => !prev);
  };

  const handleChangePhoneNumber = (phone) => {
    setPhoneNumber(phone);
  };

  const handleClickSubmit = async () => {
    const { isValid, errorMessage } = checkPhoneNumberValid(phoneNumber);
    setIsPhoneNumberValid(isValid);
    if (!isValid) {
      setPhoneNumberErrorMessage(errorMessage);
      return;
    }

    setIsOpenWhatsApp(false);
    setPhoneNumber('');
    try {
      const phoneNumberNational = getPhoneNumberNational(phoneNumber);
      const result = await axios.post(
        'https://graph.facebook.com/v18.0/182225314982205/messages',
        {
          messaging_product: 'whatsapp',
          to: phoneNumberNational,
          type: 'template',
          template: {
            name: 'hello_world',
            language: { code: 'en_US' },
          },
        },
        {
          headers: {
            Authorization:
              'Bearer EAAPe4uRdAfcBOz9iQlesJGZCDZAnwKZATaZApwZCEslaI4OA89anPyAZAQmOZBC2nKJ746CBzQ8fR0jahefJSOa9Yo6ZCFfvDZCBlZBmje1hVEsyRzJQadEetX6aZCis5WDTPWmmJPURXUSNh22i0rwZBZAq151IDXSHjMzei1itYDkmq8EZBhNZBFz6V8jfkqYBiPvHfUF8YYLCkqcqxPFXzk46gYZD',
            'Content-Type': 'application/json',
          },
        },
      );
      if (!result) {
        return;
      }

      openSnackbar('A WhatsApp Message has been sended successfully 🚀');
    } catch (e: any) {
      openSnackbar(
        <div className="whitespace-pre-line text-start">
          {e?.response?.data?.error?.message
            ? `Failed: ${e?.response?.data?.error?.message} 😭`
            : 'An unknown error occurred 😭'}
        </div>,
      );
    }
  };

  return {
    isOpenWhatsApp,
    handleClickWhatsAppButton,
    phoneNumber,
    isPhoneNumberValid,
    phoneNumberErrorMessage,
    handleChangePhoneNumber,
    handleClickSubmit,
  };
};

export { useWhatsAppForm };
